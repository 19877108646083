.footer-h{
    
    color:antiquewhite;
    justify-content: 'center';
    justify-self: center;
    align-items: center;
    
}

.footer-container{

    max-height: 60px;
    min-height: 50px;

   

   
    text-align: center;
  
}


.appimage{

    width: 100%;
    height: 70px;
}

.footer-bg{

    background-color:#191A2E;
    color:#fff;
    font-family: "Lato", sans-serif;
    font-weight: 900;


    
  
}

.footer {
 
    left: 0;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: center;
    font-style: normal;
 }

