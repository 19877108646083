.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.services-heading{
  text-align: left !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.swiper-button-next { color: #fff ;


}



.swiper-button-next {

  /*top: var(--swiper-navigation-top-offset, 40%) !important */
}


/* .swiper {
  width: 100%;
  height: 100%;
} */
/* 
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  /* display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */ 


.heading{


  justify-content: flex-start;
  text-align: left;
  margin-left: 38px;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
  text-decoration: none;
  font-size: 20px;

}


.featuredImage{

 
  width: 100%;
 height: 320px !important;

}

.news-info{

  text-align: left;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  
}
a:news-info{

  text-decoration: none !important;
}

.news-title{

  text-align: left;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.video-iframe{

  height: 600px;
  width: 100%
}


.feat-imge{


  width: 100%;
  height: 350px;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: #fff;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}


@media only screen and (max-width: 600px) {
  .about-img{
 
   width: 100% !important
  }
 
 
 
  div.card.border-0{
 
   width: 100% !important;
  }
 
  .carousel-item img{
   width: 100% !important;
   height: 250px;
 }
 .navbar-toggler{
 
   background-color: rgb(247, 244, 244) !important;
 }
 .my-top{
   text-align: center !important;
   
 }
 .top-address{
 
   display: none !important;
 }
 
 .top-clock{
 
   display: none !important;
 }
 
 .top-phone{
 
 
   text-align: center !important;
   display: block !important;
  
 }
 .quick-links{
 
   justify-content: left !important;
 
  text-align: left;
  float: left;
 
 }
 
 .top-lock{
 
   flex-direction: row !important;
   justify-content: space-between;
   justify-content: center;
   justify-content: space-evenly;
 }
 
 .app-form{
 
   width: 100% !important
 }
 
 .bottom-dec {
 
   background-color:crimson !important;
 }
 
 .appointment-desc{
 
   top:90px !important;
 }
 
 .bottom-hours{
 
   margin-top: 18px !important;
   border-bottom: 1px solid red ;
   margin-bottom: 12px;
   padding: 18px
 
 }
 
 .container{
   --bs-gutter-x: 0.2rem !important
 }



 .swiper-button-prev, .swiper-button-next{
  position: absolute;
  top: var(--swiper-navigation-top-offset, 30%);
  width: calc(var(--swiper-navigation-size) / 54* 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px -(var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ebeff4;
}
 }


.live{

  position: relative;

  background-image: url("/src/assets/img/live.jpg");

    
 height: 455px !important;

 width: 100%;

 background-repeat: no-repeat, repeat;

 background-position: center; /* Center the image */
 background-repeat: no-repeat; /* Do not repeat the image */
 background-size: cover

}

div.live-text{
  position: absolute;
  margin-top: 72px;
  text-align: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 15%;
  bottom: 55%;
}
 

